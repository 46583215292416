import { createRouter, createWebHistory } from "vue-router";
import TimeCode from "./components/TimeCode.vue";
import Dash from "./components/Dash.vue";
import Hls from "./components/Hls.vue";

const routes = [
  {
    path: "/",
    name: "main",
    component: Dash,
  },
  {
    path: "/timecode",
    name: "timecode",
    component: TimeCode,
  },
  {
    path: "/dash",
    name: "dash",
    component: Dash,
  },
  {
    path: "/hls",
    name: "hls",
    component: Hls,
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
