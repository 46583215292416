function testTimeCode(timecode) {
  return timecode.slice(9, 12) * 1;
}
function parseTimecodeString(timecode) {
  // Parses timecode strings non-drop 'hh:mm:ss:ff', drop 'hh:mm:ss;ff', or milliseconds 'hh:mm:ss:fff'
  let frames = testTimeCode(timecode);
  if (frames != null) {
    return [
      timecode.slice(0, 2) * 1,
      timecode.slice(3, 5) * 1,
      timecode.slice(6, 8) * 1,
      frames,
    ];
  } else {
    return null;
  }
}
let Timecode = {
  init: function (args) {
    let obj = Object.create(this);
    obj.framerate = obj._get(args, "framerate", 25);
    obj.drop_frame = obj._get(args, "drop_frame", false);
    obj.int_framerate = obj.setIntFramerate();
    obj.hours = false;
    obj.minutes = false;
    obj.seconds = false;
    obj.frames = false;
    obj.tcRef = Object.create({
      hours: false,
      minutes: false,
      seconds: false,
      frames: false,
    });
    obj.setTcRef(obj._get(args, "timecode_ref", "00:00:00:00"));
    obj.frame_count = obj._get(args, "frame_count", false);
    if (obj.frame_count != false) {
      obj.frameNumberToTimecode();
    } else {
      obj.set(obj._get(args, "timecode", 0));
    }
    return obj;
  },
  _get: function (dict, name, default_value) {
    if (Object.prototype.hasOwnProperty.call(dict, name)) {
      return dict[name];
    } else {
      return default_value;
    }
  },
  _zeroPad: function (number) {
    let pad = number < 10 && number >= 0 ? "0" : "";
    return pad + Math.floor(number);
  },
  setTcRef: function (tc_ref) {
    let p = parseTimecodeString(tc_ref);
    if (p != null) {
      this.tcRef.hours = p[0];
      this.tcRef.minutes = p[1];
      this.tcRef.seconds = p[2];
      this.tcRef.frames = p[3];
    }
  },
  prettyPosition: function () {
    return (
      this.getTimecode() +
      "<br> Position" +
      "<br> Frame : " +
      this.getFramePosition() +
      "<br> Second : " +
      this.getSecondDuration() +
      "<br> Milliseconde : " +
      this.getMilliSecondDuration()
    );
  },
  prettyDuration: function () {
    return (
      "" +
      this.getTimecodeDuration() +
      "<br> Frame : " +
      this.getFramePosition() +
      "<br> Second : " +
      this.getSecondDuration() +
      "<br> Milliseconde : " +
      this.getMilliSecondDuration()
    );
  },
  getFrameRate: function () {
    return this.framerate;
  },
  getFrameCount: function () {
    return this.frame_count + 1;
  },
  getFramePosition: function () {
    return this.frame_count;
  },
  getSecondDuration: function () {
    return Math.round((this.frame_count / this.framerate) * 100) / 100;
  },
  getMilliSecondDuration: function () {
    return Math.round((this.frame_count / this.framerate) * 1000) / 100;
  },
  getTimecode: function () {
    let framecount = this.setFrameCountInt([
      this.hours + this.tcRef.hours,
      this.minutes + this.tcRef.minutes,
      this.seconds + this.tcRef.seconds,
      this.frames + this.tcRef.frames,
    ]);
    let parts = this.computeTimecode(framecount);
    let delim = this.drop_frame ? ";" : ":";
    return (
      this._zeroPad(parts[0]) +
      ":" +
      this._zeroPad(parts[1]) +
      ":" +
      this._zeroPad(parts[2]) +
      delim +
      this._zeroPad(parts[3])
    );
  },
  getTimecodeDuration: function () {
    let delim = this.drop_frame ? ";" : ":";
    return (
      this._zeroPad(this.hours) +
      ":" +
      this._zeroPad(this.minutes) +
      ":" +
      this._zeroPad(this.seconds) +
      delim +
      this._zeroPad(this.frames)
    );
  },
  getTimecodeRef: function () {
    let delim = this.drop_frame ? ";" : ":";
    return (
      this._zeroPad(this.tcRef.hours) +
      ":" +
      this._zeroPad(this.tcRef.minutes) +
      ":" +
      this._zeroPad(this.tcRef.seconds) +
      delim +
      this._zeroPad(this.tcRef.frames)
    );
  },
  setIntFramerate: function () {
    if (this.framerate === "ms") {
      return 1000;
    } else {
      return Math.round(this.framerate * 1);
    }
  },
  set: function (timecode) {
    if (typeof timecode === "string") {
      this.parseTimecodeString(timecode);
      this.setFrameCount();
      this.frameNumberToTimecode();
    } else if (typeof timecode === "number") {
      this.frame_count = timecode;
      this.frameNumberToTimecode();
    }
  },
  parseTimecodeString: function (timecode) {
    let p = parseTimecodeString(timecode);
    if (p != null) {
      this.hours = p[0];
      this.minutes = p[1];
      this.seconds = p[2];
      this.frames = p[3];
    }
  },
  frameNumberToTimecode: function () {
    let parts = this.computeTimecode(this.frame_count);
    this.hours = parts[0];
    this.minutes = parts[1];
    this.seconds = parts[2];
    this.frames = parts[3];
  },
  setFrameCount: function () {
    if (this.drop_frame) {
      this.frame_count = this.setFrameCountWithDropFrame([
        this.hours - this.tcRef.hours,
        this.minutes - this.tcRef.minutes,
        this.seconds - this.tcRef.seconds,
        this.frames - this.tcRef.frames,
      ]);
    } else {
      this.frame_count = this.setFrameCountInt([
        this.hours - this.tcRef.hours,
        this.minutes - this.tcRef.minutes,
        this.seconds - this.tcRef.seconds,
        this.frames - this.tcRef.frames,
      ]);
    }
  },
  computeTimecode: function (frame_count) {
    if (this.drop_frame) {
      let d,
        m,
        framerate = this.framerate * 1,
        drop_frames = Math.round(framerate * 0.066666),
        frames_per_hour = Math.round(framerate * 60 * 60),
        frames_per_24_hours = frames_per_hour * 24,
        frames_per_10_minutes = Math.round(framerate * 60 * 10),
        frames_per_minute = Math.round(framerate * 60);
      // Roll over clock if greater than 24 hours
      frame_count = frame_count % frames_per_24_hours;
      // If time is negative, count back from 24 hours
      if (frame_count < 0) {
        frame_count = frames_per_24_hours + frame_count;
      }
      d = Math.floor(frame_count / frames_per_10_minutes);
      m = frame_count % frames_per_10_minutes;
      if (m > drop_frames) {
        frame_count =
          frame_count +
          drop_frames * 9 * d +
          drop_frames * Math.floor((m - drop_frames) / frames_per_minute);
      } else {
        frame_count = frame_count + drop_frames * 9 * d;
      }
      return [
        Math.floor(
          Math.floor(Math.floor(frame_count / this.int_framerate) / 60) / 60
        ),
        Math.floor(Math.floor(frame_count / this.int_framerate) / 60) % 60,
        Math.floor(frame_count / this.int_framerate) % 60,
        frame_count % this.int_framerate,
      ];
    } else {
      let hours = frame_count / (3600 * this.int_framerate);
      if (hours > 23) {
        hours = hours % 24;
        frame_count = frame_count - 23 * 3600 * this.int_framerate;
      }
      let minutes =
        (frame_count % (3600 * this.int_framerate)) / (60 * this.int_framerate);
      let seconds =
        ((frame_count % (3600 * this.int_framerate)) %
          (60 * this.int_framerate)) /
        this.int_framerate;
      let frames =
        ((frame_count % (3600 * this.int_framerate)) %
          (60 * this.int_framerate)) %
        this.int_framerate;
      return [
        Math.floor(hours),
        Math.floor(minutes),
        Math.floor(seconds),
        Math.floor(frames),
      ];
    }
  },
  setFrameCountInt: function (timecode_as_list) {
    return (
      (timecode_as_list[0] * 3600 +
        timecode_as_list[1] * 60 +
        timecode_as_list[2]) *
        this.int_framerate +
      timecode_as_list[3]
    );
  },
  setFrameCountWithDropFrame: function (timecode_as_list) {
    let hours = timecode_as_list[0],
      minutes = timecode_as_list[1],
      seconds = timecode_as_list[2],
      frames = timecode_as_list[3],
      drop_frames = Math.round(this.framerate * 0.066666),
      hour_frames = this.int_framerate * 60 * 60,
      minute_frames = this.int_framerate * 60,
      total_minutes = hours * 60 + minutes,
      frame_count =
        hour_frames * hours +
        minute_frames * minutes +
        this.int_framerate * seconds +
        frames -
        drop_frames * (total_minutes - Math.floor(total_minutes / 10));
    return frame_count;
  },
};

export { Timecode, testTimeCode, parseTimecodeString };
