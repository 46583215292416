<!-- FishTank 2024-->
<template lang="html">
  <div>
    <div class="container-fluid">
      <div
        class="card"
        style="
          width: 80%;
          margin-left: auto;
          margin-right: auto;
          margin-top: 10px;
          padding-top: 10px;
          padding-bottom: 10px;
          margin-bottom: 200px;
        "
      >
        <div class="card-body">
          <div class="row">
            <div class="col-sm">
              <h4 class="card-title">Manifest URL (mpd)</h4>
              <input
                v-model="manifest_url_form"
                class="form-control"
                placeholder="Manifest URL (.mpd)"
              />
              <textarea
                v-model="headers_request"
                class="form-control"
                rows="1"
                placeholder="Headers Request"
              ></textarea>
              <p v-if="manifest_url && manifest_url_form == ''">
                <b>Manifest :</b> {{ manifest_url }}
              </p>
              <div class="btn-group" role="group">
                <button type="button" class="btn btn-success" @click="load()">
                  Load
                </button>
                <button
                  type="button"
                  class="btn btn-warning"
                  @click="purgeData()"
                >
                  Reset
                </button>
              </div>
              <br />
            </div>
            <div class="col-sm">
              <h4 class="card-title">Player <samll>RxPlayer</samll></h4>
              <video controls></video>
              <div v-if="playerInfo != null">
                <span
                  v-for="(error, k) in playerInfo.Error"
                  :key="k"
                  class="error"
                >
                  {{ error }}
                </span>
              </div>
              <div class="row" v-if="player != null">
                <div class="col-sm">
                  <ul class="list-group" style="text-align: left">
                    <li class="list-group-item">
                      Player State : {{ player.getPlayerState() }}
                    </li>
                    <li class="list-group-item">
                      Player Position : {{ player.getPosition() }}
                    </li>
                    <li class="list-group-item">
                      Minimum Position : {{ player.getMinimumPosition() }}
                    </li>
                    <li class="list-group-item">
                      Maximum Position : {{ player.getMaximumPosition() }}
                    </li>
                  </ul>
                </div>
                <div class="col-sm">
                  <ul class="list-group" style="text-align: left">
                    <li class="list-group-item">
                      Error : {{ player.getError() }}
                    </li>
                    <li class="list-group-item">
                      Playback Rate : {{ player.getPlaybackRate() }}
                    </li>
                    <li class="list-group-item">
                      Volume : {{ player.getVolume() }}
                    </li>
                  </ul>
                </div>
              </div>
              <br />
              <div class="row" v-if="player != null">
                <div class="col-sm">
                  <ul class="list-group" style="text-align: left">
                    <li class="list-group-item">Video Tracks</li>
                    <li
                      class="list-group-item"
                      v-for="(video, k) in player.getAvailableVideoTracks()"
                      :style="[
                        video.active ? { border: '1px solid #0168d9' } : {},
                      ]"
                      :key="k"
                    >
                      <ul class="list-group" style="text-align: left">
                        <li class="list-group-item">
                          ID : {{ video.id }}
                          <button
                            v-if="!video.active"
                            type="button"
                            class="btn bnt-sm btn-success"
                            @click="setPlayerTrack('video', video.id)"
                          >
                            select
                          </button>
                        </li>
                      </ul>
                      Representations :<br />
                      <span
                        class="list-group-item"
                        v-for="(representation, k) in video.representations"
                        :key="k"
                      >
                        {{ representation.id }}<br />
                        - Bitrate : {{ representation.bitrate }}<br />
                        - Codec : {{ representation.codec }}<br />
                        - Frame Rate : {{ representation.frameRate }}<br />
                        - Resolution : {{ representation.width }}x{{
                          representation.height
                        }}
                      </span>
                    </li>
                  </ul>
                </div>
                <div class="col-sm">
                  <ul class="list-group" style="text-align: left">
                    <li class="list-group-item">Audio Tracks</li>
                    <li
                      class="list-group-item"
                      v-for="(audio, k) in player.getAvailableAudioTracks()"
                      :style="[
                        audio.active ? { border: '1px solid #0168d9' } : {},
                      ]"
                      :key="k"
                    >
                      <ul class="list-group" style="text-align: left">
                        <li class="list-group-item">
                          ID : {{ audio.id }}
                          <button
                            v-if="!audio.active"
                            type="button"
                            class="btn bnt-sm btn-success"
                            @click="setPlayerTrack('audio', audio.id)"
                          >
                            select
                          </button>
                        </li>
                        <li class="list-group-item" v-if="audio.language != ''">
                          Language : {{ audio.language }}
                        </li>
                        <li
                          class="list-group-item"
                          v-if="audio.normalized != ''"
                        >
                          Normalized : {{ audio.normalized }}
                        </li>
                        <li
                          class="list-group-item"
                          v-if="audio.audioDescription != ''"
                        >
                          Audio Description: {{ audio.audioDescription }}
                        </li>
                        <li class="list-group-item" v-if="audio.dub">
                          Dubbing : {{ audio.dub }}
                        </li>
                      </ul>
                      Representations :<br />
                      <span
                        class="list-group-item"
                        v-for="(representation, k) in audio.representations"
                        :key="k"
                      >
                        {{ representation.id }}<br />
                        - Bitrate : {{ representation.bitrate }}<br />
                        - Codec : {{ representation.codec }}<br />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <h4 class="card-title">XML</h4>
          <textarea
            v-model="raw"
            class="form-control"
            rows="10"
            placeholder="manifest xml"
          ></textarea>
          <span v-if="data != null">
            <h4>Http Headers</h4>
            <span v-for="(header, k) in headers_response" :key="k">
              <b>{{ cleanKey(k) }}</b
              >{{ header }}<br />
            </span>
            <hr />
            <p><b>$key</b> is generated by the application.</p>
            <h4>Manifest</h4>
            <b>Xmlns:</b> {{ data.MPD._xmlns }}<br />
            <b>Type:</b> {{ data.MPD._type }}<br />
            <b>Profiles:</b> {{ data.MPD._profiles }}<br />
            <b>Media Presentation Duration:</b>
            {{ data.MPD._mediaPresentationDuration }}<br />
            <b>Min Buffer Time:</b> {{ data.MPD._minBufferTime }}<br />
            <b>$ Media Second Duration:</b> {{ data.MPD.$mediaSecondDuration
            }}<br />
            <b>$ Periods Count:</b> {{ data.MPD.Period.length }}<br />
            <br />
            <div v-html="timeline"></div>
            <div v-for="(period, k) in data.MPD.Period" :key="k">
              <hr />
              <h4>
                Period {{ k + 1 }}
                <small
                  >[{{ period.AdaptationSet.length }} AdaptationSets]</small
                >
              </h4>
              <div v-for="(adaptationSet, k) in period.AdaptationSet" :key="k">
                <hr />
                <b>Adaptation Set {{ k + 1 }}:</b>
                <div style="text-indent: 20px" v-if="isElse(adaptationSet)">
                  <b>{{ cleanKey(k) }}</b
                  >{{ adaptationSet }}
                </div>
                <div v-else style="text-indent: 20px">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div class="chip">
                            <div class="chiplegend">Mime Type</div>
                            <div
                              class="chipbody_audio"
                              v-if="adaptationSet._mimeType == 'audio/mp4'"
                            >
                              Audio
                            </div>
                            <div
                              class="chipbody_video"
                              v-else-if="adaptationSet._mimeType == 'video/mp4'"
                            >
                              Video
                            </div>
                            <div
                              class="chipbody_subtitle"
                              v-else-if="
                                adaptationSet._mimeType == 'application/mp4'
                              "
                            >
                              Subtitle
                            </div>
                            <div
                              class="chipbody_subtitle"
                              v-else-if="
                                adaptationSet._mimeType ==
                                'application/ttml+xml'
                              "
                            >
                              Subtitle
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="chip">
                            <div class="chiplegend">Content Protection</div>
                            <div
                              class="chipbody_green"
                              v-if="adaptationSet.$protection"
                            >
                              YES
                            </div>
                            <div class="chipbody_red" v-else>NO</div>
                          </div>
                        </td>
                        <td>
                          <div class="chip">
                            <div class="chiplegend">Segments</div>
                            <div
                              class="chipbody"
                              v-if="
                                adaptationSet.SegmentTemplate &&
                                adaptationSet.SegmentTemplate.$endNumber != -1
                              "
                            >
                              {{ adaptationSet.SegmentTemplate.$endNumber }}
                            </div>
                            <div class="chipbody" v-else>1</div>
                          </div>
                        </td>
                        <td>
                          <div class="chip" v-if="adaptationSet._lang">
                            <div class="chiplegend">Language</div>
                            <div class="chipbody">
                              {{ adaptationSet._lang }}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    v-for="(values, k) in adaptationSet"
                    :key="k"
                    style="text-indent: 40px"
                  >
                    <b>{{ cleanKey(k) }}</b>
                    <span v-if="isElse(values)">{{ values }}</span>
                    <div v-if="isArray(values)" style="text-indent: 60px">
                      <div v-for="v in values" :key="v">
                        -
                        <span v-if="isElse(v)">{{ v }}</span>
                        <div v-if="isObject(v)" style="text-indent: 70px">
                          <div v-for="(vv, kk) in v" :key="kk">
                            <b>{{ cleanKey(kk) }}</b>
                            <span v-if="isElse(vv)">{{ vv }}</span>
                            <div v-else-if="isArray(vv)">
                              <div
                                v-for="(vvv, kkk) in vv"
                                :key="kkk"
                                style="text-indent: 80px"
                              >
                                -
                                <div
                                  v-for="(vvvv, kkkk) in vvv"
                                  :key="kkkk"
                                  style="text-indent: 90px"
                                >
                                  <b>{{ cleanKey(kkkk) }}</b>
                                  <span v-if="isElse(vvvv)">{{ vvvv }}</span>
                                  <div v-if="isObject(vvvv)">
                                    <div
                                      v-for="(vvvvv, kkkkk) in vvvv"
                                      :key="kkkkk"
                                      style="text-indent: 90px"
                                    >
                                      <b>{{ cleanKey(kkkkk) }}</b
                                      ><span v-if="isElse(vvvvv)">{{
                                        vvvvv
                                      }}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              v-else-if="
                                isObject(vv) &&
                                kk != '$urlinit' &&
                                kk != '$urlmedia'
                              "
                            >
                              <div
                                v-for="(vvv, kkk) in vv"
                                :key="kkk"
                                style="text-indent: 80px"
                              >
                                <b>{{ cleanKey(kkk) }}</b>
                                <span v-if="isElse(vvv)">{{ vvv }}</span>
                                <div v-if="isObject(vvv)">
                                  <div
                                    v-for="(vvvv, kkkk) in vvv"
                                    :key="kkkk"
                                    style="text-indent: 90px"
                                  >
                                    <b>{{ cleanKey(kkkk) }}</b
                                    ><span v-if="isElse(vvvv)">{{ vvvv }}</span>
                                  </div>
                                </div>
                                <div v-if="isArray(vvv)">
                                  <div
                                    v-for="vvvv in vvv"
                                    :key="vvvv"
                                    style="text-indent: 90px"
                                  >
                                    <span>{{ vvvv }}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <span
                              v-else-if="
                                isObject(vv) &&
                                (kk == '$urlinit' || kk == '$urlmedia')
                              "
                            >
                              <a v-bind:href="vv.url" target="_blank">{{
                                vv.short
                              }}</a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="isObject(values)">
                      <div
                        v-for="(vv, kk) in values"
                        :key="kk"
                        style="text-indent: 60px"
                      >
                        <b>{{ cleanKey(kk) }}</b>
                        <span v-if="kk != 'SegmentTimeline'">
                          <span v-if="isElse(vv)">{{ vv }}</span>
                          <div
                            v-if="
                              isObject(vv) &&
                              kk != '$urlinit' &&
                              kk != '$urlmedia'
                            "
                          >
                            <div
                              v-for="(vvv, kkk) in vv"
                              :key="kkk"
                              style="text-indent: 70px"
                            >
                              <b>{{ cleanKey(kkk) }}</b>
                              <span v-if="isElse(vvv)">{{ vvv }}</span>
                              <div v-if="isObject(vvv)">
                                <div
                                  v-for="(vvvv, kkkk) in vvv"
                                  :key="kkkk"
                                  style="text-indent: 80px"
                                >
                                  <b>{{ cleanKey(kkkk) }}</b
                                  ><span v-if="isElse(vvvv)">{{ vvvv }}</span>
                                </div>
                              </div>
                              <div v-if="isArray(vvv)">
                                <div
                                  v-for="vvvv in vvv"
                                  :key="vvvv"
                                  style="text-indent: 90px"
                                >
                                  <span>{{ vvvv }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <span
                            v-else-if="
                              isObject(vv) &&
                              (kk == '$urlinit' || kk == '$urlmedia')
                            "
                          >
                            <a v-bind:href="vv.url" target="_blank">{{
                              vv.short
                            }}</a>
                          </span>
                        </span>
                        <span v-if="kk == 'SegmentTimeline'">True</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { X2JS } from "./tools/xml2json";
import { DRMSystem, codec_legend } from "./tools/data";
import RxPlayer from "rx-player";
import moment from "moment";

export default {
  name: "DashManifest",
  data() {
    return {
      manifest_url_form: "",
      headers_request: null,
      manifest_url: null,
      manifest_root: null,
      headers_response: null,
      data: null,
      timeline: null,
      raw: null,
      playerInfo: null,
      player: null,
    };
  },
  mounted() {
    if (this.$route.query.url != null) {
      this.manifest_url_form = "";
      this.getData(this.$route.query.url);
    } else {
      this.getData(this.manifest_url_form);
    }
  },
  watch: {
    raw: {
      handler(raw) {
        if (raw != null) {
          if (raw != "") {
            this.parseXML();
          } else {
            this.cleanInput();
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    cleanKey(value) {
      if (typeof value === "string") {
        if (value != "toString" && value != "__prefix" && value != "__text") {
          value = value.replaceAll("_", "");
          return value.charAt(0).toUpperCase() + value.slice(1) + ": ";
        }
        return "";
      }
      return value;
    },
    isString: function (value) {
      return typeof value === "string";
    },
    isArray: function (value) {
      return {}.toString.call(value) === "[object Array]";
    },
    isObject: function (value) {
      return {}.toString.call(value) === "[object Object]";
    },
    isElse: function (value) {
      return (
        {}.toString.call(value) !== "[object Object]" &&
        {}.toString.call(value) !== "[object Array]" &&
        typeof value !== "function"
      );
    },
    isValidHttpUrl: function (string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    purgeData: function () {
      this.cleanInput();
      this.manifest_root = null;
      this.manifest_url_form = null;
      this.manifest_url = null;
      this.$notify({
        text: "purged",
        type: "info",
        duration: 1000,
      });
    },
    cleanInput: function () {
      this.data = null;
      this.raw = null;
      this.timeline = null;
      this.headers_response = null;
      this.$el.querySelector("video").style.display = "none";
      this.playerInfo = null;
      this.player = null;
    },
    load: function () {
      this.cleanInput();
      if (
        this.manifest_url != this.manifest_url_form &&
        this.isValidHttpUrl(this.manifest_url_form)
      ) {
        this.getData(this.manifest_url_form);
      } else {
        this.getData(this.manifest_url);
      }
      if (this.manifest_url == null) {
        this.parseXML();
      }
      this.$notify({
        text: "Loaded",
        type: "info",
        duration: 1000,
      });
    },
    setPlayerTrack: function (kind, id) {
      switch (kind) {
        case "video":
          this.player.setVideoTrack(id);
          break;
        case "audio":
          this.player.setAudioTrack(id);
          break;
      }
    },
    setPlayer: function (manifest_url) {
      this.player = new RxPlayer({
        videoElement: this.$el.querySelector("video"),
      });
      this.playerInfo = {};
      this.playerInfo["Error"] = [];
      this.$el.querySelector("video").style.display = "block";
      this.player.loadVideo({
        transport: "dash",
        url: manifest_url,
        autoPlay: false,
      });

      this.player.addEventListener("error", (err) => {
        if (this.isValidMPD()) {
          this.$el.querySelector("video").style.display = "none";
          this.playerInfo["Error"].push(err);
          this.$notify({
            text: err,
            type: "error",
            fullWidth: true,
            duration: 3000,
          });
        }
      });
      this.player.addEventListener("periodChange", (period) => {
        this.playerInfo["period"] = period;
      });
      this.player.addEventListener("playerStateChange", (state) => {
        //lag
        if (state == "BUFFERING" || state == "SEEKING") {
          this.playerInfo["lag_start"] = new Date();
        }
        if (state == "PAUSED") {
          this.playerInfo["lag"] = new Date() - this.playerInfo["lag_start"];
        }
        if (state == "LOADED") {
          this.playerInfo["framerate"] = parseFloat(
            this.player.getVideoTrack().representations[0].frameRate
          );
          this.playerInfo["frame_duration"] = parseFloat(
            (1 / this.playerInfo["framerate"]).toFixed(2)
          );
          this.playerInfo["frame_duration_ms"] =
            this.playerInfo["frame_duration"] * 1000;
        }
      });
    },
    getData: function (url) {
      if (this.isValidHttpUrl(url)) {
        this.cleanInput();
        this.manifest_url = url;
        let axiosConig = { timeout: 5000 };
        if (this.headers_request) {
          axiosConig.headers = JSON.parse(this.headers_request);
        }
        axios
          .get(url, axiosConig)
          .then((response) => {
            this.raw = response.data;
            this.headers_response = response.headers;
          })
          .catch((error) => {
            this.cleanInput();
            console.error(error);
            this.$notify({
              text: error,
              type: "error",
              fullWidth: true,
              duration: 3000,
            });
          });
      }
    },
    addCustomData: function () {
      this.data.MPD.$mediaSecondDuration = moment
        .duration(this.data.MPD._mediaPresentationDuration)
        .asSeconds();
      if (this.data.MPD.Period.length == undefined) {
        this.data.MPD.Period = [this.data.MPD.Period];
      }
      this.data.MPD.Period.forEach((period) => {
        if (period.AdaptationSet.length == undefined) {
          period.AdaptationSet = [period.AdaptationSet];
        }
        period.AdaptationSet.forEach((element, indexAdaptationSet) => {
          period.$protection = false;
          let init = "";
          let media = "";
          let manifest_root = "";

          if (this.manifest_url != null) {
            this.manifest_root = this.manifest_url.slice(
              0,
              this.manifest_url.lastIndexOf("/")
            );
            manifest_root = this.manifest_url.slice(
              0,
              this.manifest_url.lastIndexOf("/")
            );
          }
          if (
            Object.prototype.hasOwnProperty.call(element, "ContentProtection")
          ) {
            element.$protection = true;
            element.ContentProtection.forEach((cp) => {
              if (Object.prototype.hasOwnProperty.call(cp, "_schemeIdUri")) {
                if (
                  Object.prototype.hasOwnProperty.call(
                    DRMSystem,
                    cp._schemeIdUri
                  )
                ) {
                  cp.SystemName = DRMSystem[cp._schemeIdUri];
                }
              }
            });
          }
          // segmentation counter
          if (
            Object.prototype.hasOwnProperty.call(element, "SegmentTemplate")
          ) {
            init = element.SegmentTemplate._initialization;
            media = element.SegmentTemplate._media;
            if (
              Object.prototype.hasOwnProperty.call(element, "SegmentTimeline")
            ) {
              element.SegmentTemplate.$endNumber = 0;
              if (
                typeof element.SegmentTemplate.SegmentTimeline.S._d ===
                "undefined"
              ) {
                element.SegmentTemplate.SegmentTimeline.S.forEach((seg) => {
                  if (typeof seg._r === "undefined") {
                    element.SegmentTemplate.$endNumber += 1; //+= parseInt(seg._d, 10)
                  } else {
                    element.SegmentTemplate.$endNumber += parseInt(seg._r, 10);
                  }
                });
              } else {
                element.SegmentTemplate.$endNumber = 1;
              }
            } else {
              element.SegmentTemplate.$endNumber = Math.ceil(
                this.data.MPD.$mediaSecondDuration /
                  (element.SegmentTemplate._duration /
                    element.SegmentTemplate._timescale)
              );
            }
          }

          if (Object.prototype.hasOwnProperty.call(element, "Representation")) {
            if (element.Representation.length == undefined) {
              element.Representation = [element.Representation];
            }
            // MIMETYPE
            if (!Object.prototype.hasOwnProperty.call(element, "_mimeType")) {
              if (
                Object.prototype.hasOwnProperty.call(
                  element.Representation[0],
                  "_mimeType"
                )
              ) {
                element._mimeType = element.Representation[0]._mimeType;
              }
            }
            // CODEC INFO
            element.Representation.forEach((representation) => {
              if (
                Object.prototype.hasOwnProperty.call(representation, "_codecs")
              ) {
                if (codec_legend(representation._codecs) != "") {
                  representation.$codecLegend = codec_legend(
                    representation._codecs
                  );
                }
              }
            });

            if (
              Object.prototype.hasOwnProperty.call(element, "SegmentTemplate")
            ) {
              element.Representation.forEach((representation) => {
                if (
                  Object.prototype.hasOwnProperty.call(
                    representation,
                    "ContentProtection"
                  )
                ) {
                  period.AdaptationSet[indexAdaptationSet].$protection = true;
                  representation.ContentProtection.forEach((cp) => {
                    if (
                      Object.prototype.hasOwnProperty.call(cp, "_schemeIdUri")
                    ) {
                      if (
                        Object.prototype.hasOwnProperty.call(
                          DRMSystem,
                          cp._schemeIdUri
                        )
                      ) {
                        cp.SystemName = DRMSystem[cp._schemeIdUri];
                      }
                    }
                  });
                }
                if (
                  Object.prototype.hasOwnProperty.call(
                    representation,
                    "_codecs"
                  )
                ) {
                  if (codec_legend(representation._codecs) != "") {
                    representation.$codecLegend = codec_legend(
                      representation._codecs
                    );
                  }
                }
                let local_init = init.replace(
                  "$RepresentationID$",
                  representation._id
                );
                let local_media = media.replace(
                  "$RepresentationID$",
                  representation._id
                );
                if (this.isValidHttpUrl(local_init)) {
                  this.manifest_root = "";
                  representation.$urlinit = {
                    short: local_init,
                    url: local_init,
                  };
                  representation.$urlmedia = {
                    short: local_media,
                    url: local_media,
                  };
                } else {
                  representation.$urlinit = {
                    short: local_init,
                    url: `${manifest_root}/${local_init}`,
                  };
                  representation.$urlmedia = {
                    short: local_media,
                    url: `${manifest_root}/${local_media}`,
                  };
                }
                if (
                  Object.prototype.hasOwnProperty.call(
                    element,
                    "SegmentTemplate"
                  ) &&
                  Object.prototype.hasOwnProperty.call(element, "_startNumber")
                ) {
                  representation.$urlmedia.url =
                    representation.$urlmedia.url.replace(
                      "$Number$",
                      element.SegmentTemplate._startNumber
                    );
                  if (
                    element.SegmentTemplate._startNumber ==
                    element.SegmentTemplate.$endNumber
                  ) {
                    representation.$urlmedia.short =
                      representation.$urlmedia.short.replace(
                        "$Number$",
                        element.SegmentTemplate._startNumber
                      );
                  } else {
                    representation.$urlmedia.short =
                      representation.$urlmedia.short.replace(
                        "$Number$",
                        `[${element.SegmentTemplate._startNumber}-${element.SegmentTemplate.$endNumber}]`
                      );
                  }
                }
              });
            } else {
              if (element.Representation.BaseURL != null) {
                if (this.isValidHttpUrl(element.Representation.BaseURL)) {
                  this.manifest_root = "";
                  element.Representation.$urlinit = {
                    short: element.Representation.BaseURL,
                    url: element.Representation.BaseURL,
                  };
                } else {
                  element.Representation.$urlinit = {
                    short: element.Representation.BaseURL,
                    url: `${manifest_root}/${element.Representation.BaseURL}`,
                  };
                }
              } else {
                element.Representation.forEach((representation) => {
                  if (this.isValidHttpUrl(representation.BaseURL)) {
                    this.manifest_root = "";
                    representation.$urlinit = {
                      short: representation.BaseURL,
                      url: representation.BaseURL,
                    };
                  } else {
                    representation.$urlinit = {
                      short: representation.BaseURL,
                      url: `${manifest_root}/${representation.BaseURL}`,
                    };
                  }
                });
              }
            }
          }
        });
      });
    },
    renderTimeline: function () {
      this.timeline = "<table><tr>";
      let periodes_count = 0;
      this.data.MPD.Period.forEach((period) => {
        periodes_count += 1;
        this.timeline += `<th class="period_head">Period ${periodes_count}`;
        if (period._start != null) {
          let sec = moment.duration(period._start).asSeconds();
          this.timeline += `<br>Start  ${sec}s [${period._start}]`;
        }
        if (period._duration != null) {
          let sec = moment.duration(period._duration).asSeconds();
          this.timeline += `<br>Duration ${sec}s [${period._duration}]`;
        }
        this.timeline += `</th>`;
      });
      this.timeline += "</tr><tr>";
      this.data.MPD.Period.forEach((period) => {
        this.timeline += '<td class="period_body">';
        let adpatation_count = 0;
        period.AdaptationSet.forEach((adaptationSet) => {
          let adaptation_class = "adaptationset_subtitle";
          if (adaptationSet._mimeType == "audio/mp4") {
            adaptation_class = "adaptationset_audio";
          }
          if (adaptationSet._mimeType == "video/mp4") {
            adaptation_class = "adaptationset_video";
          }
          this.timeline += `<div class="${adaptation_class}">`;
          adpatation_count += 1;
          this.timeline += `AdaptationSet ${adpatation_count} ${adaptationSet._mimeType}<br>`;
          adaptationSet.Representation.forEach((representation) => {
            if (representation._id != null) {
              this.timeline += `<div style="text-indent:10px;">${representation._id}</div>`;
            }
            if (adaptationSet._mimeType == "video/mp4") {
              this.timeline += `<div style="text-indent:10px;">Representation</div><div style="text-indent:30px;">codec ${representation._codecs} | ${representation._bandwidth} bits | ${representation._frameRate} FPS | ${representation._width}x${representation._height}</div>`;
              if (codec_legend(representation._codecs) != "") {
                this.timeline += `<div style="text-indent:30px;">Codec Legend : ${codec_legend(
                  representation._codecs
                )}</div>`;
              }
            }
            if (adaptationSet._mimeType == "audio/mp4") {
              this.timeline += `<div style="text-indent:10px;">Representation</div><div style="text-indent:30px;">codec ${representation._codecs} | ${representation._bandwidth} bits | ${representation._audioSamplingRate} hz</div>`;
              if (codec_legend(representation._codecs) != "") {
                this.timeline += `<div style="text-indent:30px;">Codec Legend : ${codec_legend(
                  representation._codecs
                )}</div>`;
              }
            }
            if (
              Object.prototype.hasOwnProperty.call(
                representation,
                "representation"
              )
            ) {
              this.timeline += `<div style="text-indent:10px;color:red">Encrypted</div>`;
            }
          });
          if (
            Object.prototype.hasOwnProperty.call(
              adaptationSet,
              "ContentProtection"
            )
          ) {
            this.timeline += `<div style="text-indent:10px;color:red">Encrypted</div>`;
          }
          this.timeline += `</div>`;
        });
        this.timeline += "</td>";
      });
      this.timeline += "</tr></table>";
    },
    isValidMPD: function () {
      return this.data != null;
    },
    parseXML: function () {
      try {
        if (this.raw != null) {
          if (this.raw.includes("MPD")) {
            let x2js = new X2JS();
            let data = x2js.xml_str2json(this.raw);
            if (Object.prototype.hasOwnProperty.call(data, "MPD")) {
              this.data = data;
              this.addCustomData();
              this.renderTimeline();
            }
            if (this.manifest_url != null) {
              this.setPlayer(this.manifest_url);
            } else {
              let element = document.createElement("a");
              let manifest = this.raw;
              element.setAttribute(
                "href",
                "data:text/application/dash+xml;charset=utf-8," +
                  encodeURIComponent(manifest)
              );
              element.setAttribute("download", "manifest.mdp");
              this.setPlayer(element.href);
            }
          }
        }
      } catch (error) {
        this.data = null;
        console.error(error);
        this.$notify({
          text: error,
          type: "error",
          fullWidth: true,
          duration: 3000,
        });
      }
    },
  },
};
</script>
