import { createApp } from "vue";
import MainView from "./MainView.vue";
import router from "./router";

import axios from "axios";
import VueAxios from "vue-axios";
import Notifications from "@kyvg/vue3-notification";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

const app = createApp(MainView);

app.use(router).use(VueAxios, axios).use(Notifications).mount("#app");
