<!-- FishTank 2024-->
<template lang="html">
  <div>
    <div class="container-fluid">
      <div
        class="card"
        style="
          width: 80%;
          margin-left: auto;
          margin-right: auto;
          margin-top: 10px;
          padding-top: 10px;
          padding-bottom: 10px;
          margin-bottom: 200px;
        "
      >
        <div class="card-body">
          <div class="row">
            <div class="col-sm">
              <h4 class="card-title">Playlist URL (m3u)</h4>
              <input
                v-model="playlist_url_form"
                class="form-control"
                placeholder="Playlist URL (m3u)"
              />
              <textarea
                v-model="headers_request"
                class="form-control"
                rows="1"
                placeholder="Headers Request"
              ></textarea>
              <p v-if="playlist_url && playlist_url_form == ''">
                <b>playlist :</b> {{ playlist_url }}
              </p>
              <div class="btn-group" role="group">
                <button type="button" class="btn btn-success" @click="load()">
                  Load
                </button>
                <button
                  type="button"
                  class="btn btn-warning"
                  @click="purgeData()"
                >
                  Reset
                </button>
              </div>
              <br />
            </div>
            <div class="col-sm">
              <h4 class="card-title">Player <samll>Hls.js</samll></h4>
              <video controls id="video"></video>
            </div>
          </div>
        </div>
        <hr />
        <h4 class="card-title">m3u8</h4>
        <textarea
          v-model="raw"
          class="form-control"
          rows="10"
          placeholder="m3u8"
        ></textarea>
        <span v-if="headers_response != null">
          <h4>Http Headers</h4>
          <span v-for="(header, k) in headers_response" :key="k">
            <b>{{ cleanKey(k) }}</b
            >{{ header }}<br />
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Hls from "hls.js";

export default {
  name: "HlsPlaylist",
  data() {
    return {
      playlist_url_form: "",
      headers_request: null,
      playlist_url: null,
      headers_response: null,
      raw: null,
      player: null,
    };
  },
  mounted() {
    if (this.$route.query.url != null) {
      this.playlist_url_form = "";
      this.getData(this.$route.query.url);
    } else {
      this.getData(this.playlist_url_form);
    }
  },
  watch: {
    playlist_url: {
      handler(playlist_url) {
        if (playlist_url != null) {
          if (playlist_url != "") {
            this.setPlayer(playlist_url);
          } else {
            this.cleanInput();
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    cleanKey(value) {
      if (typeof value === "string") {
        if (value != "toString" && value != "__prefix" && value != "__text") {
          value = value.replaceAll("_", "");
          return value.charAt(0).toUpperCase() + value.slice(1) + ": ";
        }
        return "";
      }
      return value;
    },
    isValidHttpUrl: function (string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    purgeData: function () {
      this.playlist_url_form = null;
      this.playlist_url = null;
      this.cleanInput();
      this.$notify({
        text: "purged",
        type: "info",
        duration: 1000,
      });
    },
    cleanInput: function () {
      this.raw = null;
      this.headers_response = null;
      if (this.player) {
        this.player.detachMedia();
      }
      this.player = null;
      document.getElementById("video").style.display = "none";
    },
    load: function () {
      this.cleanInput();
      if (
        this.playlist_url != this.playlist_url_form &&
        this.isValidHttpUrl(this.playlist_url_form)
      ) {
        this.getData(this.playlist_url_form);
      } else {
        this.getData(this.playlist_url);
      }
    },
    setPlayer: function (playlist_url) {
      var video = document.getElementById("video");
      this.player = new Hls();
      this.player.loadSource(playlist_url);
      this.player.attachMedia(video);
      this.$el.querySelector("video").style.display = "block";
    },
    getData: function (url) {
      if (this.isValidHttpUrl(url)) {
        this.$notify({
          text: "Loading",
          type: "info",
          duration: 1000,
        });
        this.cleanInput();
        let axiosConig = { timeout: 5000 };
        if (this.headers_request) {
          axiosConig.headers = JSON.parse(this.headers_request);
        }
        axios
          .get(url, axiosConig)
          .then((response) => {
            this.playlist_url = url;
            this.raw = response.data;
            this.headers_response = response.headers;
          })
          .catch((error) => {
            this.cleanInput();
            console.error(error);
            this.$notify({
              text: error,
              type: "error",
              fullWidth: true,
              duration: 3000,
            });
          });
      }
    },
  },
};
</script>
