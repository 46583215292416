let DRMSystem = {
  "urn:uuid:6dd8b3c3-45f4-4a68-bf3a-64168d01a4a6": "ABV DRM (MoDRM)",
  "urn:uuid:f239e769-efa3-4850-9c16-a903c6932efb":
    "Adobe Primetime DRM version 4",
  "urn:uuid:616c7469-6361-7374-2d50-726f74656374": "Alticast",
  "urn:uuid:94ce86fb-07ff-4f43-adb8-93d2fa968ca2": "Apple FairPlay",
  "urn:uuid:279fe473-512c-48fe-ade8-d176fee6b40f": "Arris Titanium",
  "urn:uuid:3d5e6d35-9b9a-41e8-b843-dd3c6e72c42c": "ChinaDRM",
  "urn:uuid:3ea8778f-7742-4bf9-b18b-e834b2acbd47": "Clear Key AES-128",
  "urn:uuid:be58615b-19c4-4684-88b3-c8c57e99e957": "Clear Key SAMPLE-AES",
  "urn:uuid:e2719d58-a985-b3c9-781a-b030af78d30e": "Clear Key DASH-IF",
  "urn:uuid:644fe7b5-260f-4fad-949a-0762ffb054B4": "CMLA (OMA DRM)",
  "urn:uuid:37c33258-7b99-4c7e-b15d-19af74482154": "Commscope Titanium V3",
  "urn:uuid:45d481cb-8fe0-49c0-ada9-ab2d2455b2f2": "CoreCrypt",
  "urn:uuid:cf4e3e3-62f1-5818-7ba6-0a6fe33ff3dd": "DigiCAP SmartXess",
  "urn:uuid:35bf197b-530e-42d7-8b65-1b4bf415070f": "DivX DRM Series 5",
  "urn:uuid:80a6be7e-1448-4c37-9e70-d5aebe04c8d2": "Irdeto Content Protection",
  "urn:uuid:5e629af5-38da-4063-8977-97ffbd9902d4":
    "Marlin Adaptive Streaming Simple Profile V1.0",
  "urn:uuid:9a04f079-9840-4286-ab92-e65be0885f95": "Microsoft PlayReady",
  "urn:uuid:6a99532d-869f-5922-9a91-113ab7b1e2f3": "MobiTV DRM",
  "urn:uuid:adb41c24-2dbf-4a6d-958b-4457c0d27b95": "Nagra MediaAccess PRM 3.0",
  "urn:uuid:1f83e1e8-6ee9-4f0d-ba2f-5ec4e3ed1a66": "SecureMedia",
  "urn:uuid:992c46e6-c437-4899-b6a0-50fa91ad0e39": "SecureMedia SteelKnot",
  "urn:uuid:a68129d3-575b-4f1a-9cba-3223846cf7c3":
    "Synamedia/Cisco/NDS VideoGuard DRM",
  "urn:uuid:aa11967f-cc01-4a4a-8e99-c5d3dddfea2d": "Unitend DRM (UDRM)",
  "urn:uuid:9a27dd82-fde2-4725-8cbc-4234aa06ec09": "Verimatrix VCAS",
  "urn:uuid:b4413586-c58c-ffb0-94a5-d4896c1af6c3": "Viaccess-Orca DRM (VODRM)",
  "urn:uuid:793b7956-9f94-4946-a942-23e7ef7e44b4": "VisionCrypt",
  "urn:uuid:1077efec-c0b2-4d02-ace3-3c1e52e2fb4b": "W3C Common PSSH box",
  "urn:uuid:edef8ba9-79d6-4ace-a3c8-27dcd51d21ed":
    "Widevine Content Protection",
};

let CodecDescription = {
  //AUDIO
  "mp4a.40.2": "MPEG-4 AAC",
  "mp4a.40.29": "MPEG-4 HE-AAC v2",
  "mp4a.40.5": "MPEG-4 HE-AAC",
  "mp4a.40.42": "MPEG-D USAC (xHE-AAC)",
  "mp4a.40.02": "M4A LC-AAC",
  "mp4a.40.05": "M4A HE-AAC",
  "ec-3": "Enhanced AC-3",
  "mp4a.40.34": "MP3",
  mp3: "MP3",
  "mp4a.69": "MP3",
  "mp4a.6B": "MP3",
  dtsc: "DTS core",
  dtsh: "DTS-HD core+extension",
  dtse: "DTS-HD LBR",
  dtsx: "DTS-UHD Profile 2",
  dtsy: "DTS-UHD Profile 3",
  "mhm1.0x0B": "MPEG-H Audio LC Profile Level 1",
  "mhm1.0x0C": "MPEG-H Audio LC Profile Level 2",
  "mhm1.0x0D": "MPEG-H Audio LC Profile Level 3",
  "mhm2.0x0B": "MPEG-H Audio LC Profile Level 1 multistream",
  "mhm2.0x0C": "MPEG-H Audio LC Profile Level 2 multistream",
  "mhm2.0x0D": "MPEG-H Audio LC Profile Level 3 multistream",
  //VIDEO
  "vp8, vorbis": "VP8",
  "vp9, vorbis": "VP9",
  "avc1.42001e": "H.264 Baseline Profile level 3.0",
  "avc1.66.30": "H.264 Baseline Profile level 3.0",
  "avc1.42001f": "H.264 Baseline Profile level 3.1",
  "avc1.4d001e": "H.264 Main Profile level 3.0",
  "avc1.77.30": "H.264 Main Profile level 3.0",
  "avc1.64001e": "H.264 Main Profile level 3.0",
  "avc1.640016": "H.264 high Profile level 2.2",
  "avc1.4d001f": "H.264 Main Profile level 3.1",
  "avc1.4d401f": "H.264 Main Profile level 3.1",
  "avc1.4d0028": "H.264 Main Profile level 4.0",
  "avc1.64001f": "H.264 High Profile level 3.1",
  "avc3.64001f": "H.264 High Profile level 3.1",
  "avc1.640028": "H.264 High Profile level 4.0",
  "avc3.640028": "H.264 High Profile level 4.0",
  "avc1.640029": "H.264 High Profile level 4.1",
  "avc1.64002a": "H.264 High Profile level 4.2",
  "avc3.64002a": "H.264 High Profile level 4.2",
  "hev1.1.6.l123.b0": "HEVC Main profile",
  "hvc1.1.6.l123.b0": "HEVC Main profile",
  "hev1.1.6.l150.b0": "HEVC Main profile 5,0",
  "hvc1.1.6.l150.b0": "HEVC Main profile 5,0",
  "hev1.1.6.l153.b0": "HEVC Main profile 5,1",
  "hev1.2.6.l150.b0": "HEVC Main 10 profile 5,0",
  "hev1.2.6.l153.b0": "HEVC Main 10 profile 5,1",
  "hev1.2.4.l123.b0": "HEVC Main 10 profile",
  "hvc1.2.4.l123.b0": "HEVC Main 10 profile",
  "hev1.2.4.l153.b0": "HEVC Main 10 profile",
  "hvc1.2.4.l153.b0": "HEVC Main 10 profile",
  "dvhe.05.06": "Dolby Vision 4K24fps",
  "dvhe.05.07": "Dolby Vision 4K30fps",
  "dvhe.05.09": "Dolby Vision 4K60fps",
  "dvhe.08.06": "Dolby Vision 4K24fps HEVC compatible",
  "dvhe.08.07": "Dolby Vision 4K30fps HEVC compatible",
  "dvhe.08.09": "Dolby Vision 4K60fps HEVC compatible",
  "vvc1.1.l67.cqa.o0+3": "VVC Main 10 profile",
  "vvci.1.l67.cqa.o0+3": "VVC Main 10 profile",
  "evc1.vprf0.vlev123": "EVC Baseline profile",
  "evc1.vprf1.vlev153": "EVC Main profile",
};

function codec_legend(code) {
  if (
    Object.prototype.hasOwnProperty.call(CodecDescription, code.toLowerCase())
  ) {
    return CodecDescription[code.toLowerCase()];
  }
  return "";
}

export { DRMSystem, CodecDescription, codec_legend };
