<template>
  <nav class="navbar navbar-dark bg-dark navbar-expand-lg sticky-top">
    <div class="container-fluid">
      <span class="navbar-brand">
        <div class="logo">Timeline</div>
      </span>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link :to="'/dash'">
              <a class="nav-link active">Dash</a>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="'/hls'">
              <a class="nav-link active">HLS</a>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="'/timecode'">
              <a class="nav-link active">Timecode</a>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <notifications position="top center" />
  <router-view></router-view>
  <footer class="flex-shrink-0 py-3 bg-dark text-white-50 fixed_footer">
    <div class="container text-left">
      <div class="row">
        <div class="col-1 col-md-1">
          <small>Our tools</small>
        </div>
        <div class="col-4 col-md-4">
          <small>
            <span class="nav-item">
              <a
                href="https://cpl.fishtank.cloud/"
                style="color: #e5e5e5"
                target="_blank"
                >CPL | Translator</a
              >
              <small> : DCP & IMF XML(CPL & PKL) parser</small>
            </span>
            <br />
            <span class="nav-item">
              <a
                href="https://divo.fishtank.cloud/"
                style="color: #e5e5e5"
                target="_blank"
                >DiVo</a
              >
              <small> : Dolby Vision & Atmos XML parser</small>
            </span>
            <br />
            <span class="nav-item">
              <a
                href="https://timeline.fishtank.cloud/"
                style="color: #e5e5e5"
                target="_blank"
                >Timeline</a
              >
              <small> : Dash, HLS and Timecode sandbox</small>
            </span>
          </small>
        </div>
        <div class="col-6 col-md-6 mb-6">
          <small>
            &copy; 2024
            <a
              href="https://fishtank.cloud/"
              style="color: #e5e5e5"
              target="_blank"
              >FishTank Cloud</a
            >
            All rights reserved.
            <br />
            <small
              >A division of
              <a
                href="https://trenchdigital.net"
                style="color: #e5e5e5"
                target="_blank"
                >Trench Digital LTD</a
              >.</small
            >
          </small>
        </div>
      </div>
    </div>
  </footer>
</template>

<style>
@import "./assets/main.css";
</style>

<script>
export default {
  name: "MainView",
  created() {
    document.title = "Timeline";
  },
};
</script>
