<!-- FishTank 2024-->
<template lang="html">
  <div>
    <div class="container-fluid">
      <div
        class="card"
        style="
          width: 80%;
          position: absolute;
          top: 15%;
          left: 10%;
          margin-left: auto;
          margin-right: auto;
          padding-top: 10px;
          padding-bottom: 10px;
          margin-bottom: 200px;
        "
      >
        <div class="card-body">
          <h4 class="card-title">Framerate</h4>
          <input v-model="framerate_input" />
          <br />
          <br />
          <h5>In one second of media at {{ framerate }} fps</h5>
          <span
            v-bind:class="[
              { firstframe: index == 0 },
              { lastframe: index == frameCountSec.length - 1 },
              'frame',
            ]"
            v-for="(frame, index) in frameCountSec"
            :key="frame"
            >{{ frame }}</span
          >
          <br />
          <br />
          <table class="table table-sm time_tab">
            <tbody>
              <tr>
                <td class="table_time_td">
                  Timecode Ref <input v-model="timecode_ref_string" />
                </td>
                <td class="table_time_td">
                  Timecode In <input v-model="timecode_in_string" /><br />
                  <br />
                  <div
                    class="timecode_info"
                    v-html="timecode_o_in.prettyPosition()"
                  ></div>
                </td>
                <td class="table_time_td">
                  Duration
                  <br /><br />
                  <div
                    class="timecode_info"
                    v-html="timecode_o_duration.prettyDuration()"
                  ></div>
                </td>
                <td class="table_time_td">
                  Timecode Out <input v-model="timecode_out_string" /><br />
                  <br />
                  <div
                    class="timecode_info"
                    v-html="timecode_o_out.prettyPosition()"
                  ></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Timecode, testTimeCode } from "./tools/timecode";

export default {
  name: "TimeCode",
  data() {
    return {
      framerate: 25,
      frameCountSec: [],
      framerate_input: 25,
      timecode_ref_string: "10:00:00:00",
      timecode_in_string: "",
      timecode_out_string: "",
      timecode_o_in: Timecode.init({
        framerate: 25,
        timecode: "10:00:00:00",
        timecode_ref: "10:00:00:00",
      }),
      timecode_o_duration: Timecode.init({
        framerate: 25,
        frame_count: 90000,
        timecode_ref: "00:00:00:00",
      }),
      timecode_o_out: Timecode.init({
        framerate: 25,
        timecode: "10:59:59:24",
        timecode_ref: "10:00:00:00",
      }),
    };
  },
  mounted() {
    this.setFrameCountSec();
  },
  watch: {
    framerate_input: {
      handler(val) {
        let dataN = Number(val);
        if (!isNaN(dataN)) {
          if (dataN < 1) {
            this.framerate = 1;
          } else {
            this.framerate = dataN;
          }
          this.setFrameCountSec(val);
          this.setTimeLine();
        }
      },
      deep: true,
    },
    timecode_ref_string: {
      handler(val) {
        if (testTimeCode(val) != null) {
          this.timecode_ref_string = val;
          this.setTimeLine();
        }
      },
      deep: true,
    },
    timecode_in_string: {
      handler(val) {
        if (testTimeCode(val) != null) {
          this.timecode_in_string = val;
          this.setTimeLine();
        }
      },
      deep: true,
    },
    timecode_out_string: {
      handler(val) {
        if (testTimeCode(val) != null) {
          this.timecode_out_string = val;
          this.setTimeLine();
        }
      },
      deep: true,
    },
  },
  methods: {
    setTimeLine: function () {
      this.timecode_o_in = Timecode.init({
        framerate: this.framerate,
        timecode: this.timecode_in_string,
        timecode_ref: this.timecode_ref_string,
      });
      this.timecode_o_out = Timecode.init({
        framerate: this.framerate,
        timecode: this.timecode_out_string,
        timecode_ref: this.timecode_ref_string,
      });
      this.timecode_o_duration = Timecode.init({
        framerate: this.framerate,
        frame_count:
          this.timecode_o_out.getFramePosition() +
          1 -
          this.timecode_o_in.getFramePosition(),
      });
    },
    setFrameCountSec: function () {
      let frameList = Array.from(Array(this.framerate).keys());
      let frameListSplited = frameList.slice(0, 24);
      if (this.framerate <= 25) {
        this.frameCountSec = frameList;
      } else {
        frameListSplited.push(" . . . ");
        frameListSplited = frameListSplited.concat(
          frameList.slice(frameList.length - 1, frameList.length)
        );
        this.frameCountSec = frameListSplited;
      }
    },
  },
};
</script>
